import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";
import { Card, Flex, Text } from "@mantine/core";
import { MdOpenInNew } from "react-icons/md";

type TestimonialData = {
  author: string;
  quote: string;
  link?: string;
};

const testimonials: TestimonialData[] = [
  {
    author: "Science & Physics Teacher",
    quote:
      "The free program Podsie dramatically increased both engagement and results of my high school students using the evidence-backed techniques of retrieval practice and spaced repetition. The average grade improved from 21% below to 38% above national standards.",
    link: "https://www.reddit.com/r/Anki/comments/u1cm4e/my_experience_with_implementing_spaced_repetition/",
  },
  {
    author: "Michael R, Math Teacher",
    quote:
      "Podsie has helped reduce my workload as a teacher! The making of many paper copies has gone, it is very quick to check pupils and their progress, in much more detail as well.",
  },
  {
    author: "Emma B, Science Teacher",
    quote:
      "I love the fact that the 'spaced practice' aspect of Podsie is supported by cognitive research. Podsie has been an amazing way for my students to study all of the details they need to know, while also challenging them to expand their ways of thinking.",
  },
];

const Testimonial = ({
  testimonial,
}: {
  testimonial: TestimonialData;
  style?: string;
}) => {
  return (
    <Carousel.Slide key={testimonial.author}>
      <Card
        radius="lg"
        className="bg-primary-50 p-12 flex h-full flex-col items-center justify-center"
      >
        <img
          src="/assets/testimonials-quotation.svg"
          className="mb-4 h-8"
          alt='"'
        />
        <Text
          component="p"
          className="text-text-secondary text-lg font-bold text-center mb-4"
        >
          {testimonial.quote}
        </Text>
        {testimonial.link ? (
          <Flex align="center" gap="xs">
            <Text
              component="a"
              href={testimonial.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 underline text-md font-normal text-center flex items-center transition-colors"
            >
              {testimonial.author}
              <MdOpenInNew className="w-4 h-4 ml-1" aria-hidden="true" />
            </Text>
          </Flex>
        ) : (
          <Text
            component="p"
            className="text-slate-secondary text-md font-normal text-center mb-4"
          >
            {testimonial.author}
          </Text>
        )}
      </Card>
    </Carousel.Slide>
  );
};

export const Testimonials = () => {
  return (
    <Flex
      justify="center"
      align="center"
      className="bg-gray-50 py-16 overflow-x-clip"
    >
      <Carousel
        slideGap={{ base: "md", sm: "xl" }}
        slideSize={{ base: "80%", sm: "60%" }}
        align="center"
        withControls
        withIndicators
        loop
        styles={{ control: { opacity: 1 } }}
      >
        {testimonials.map((testimonial) => (
          <Testimonial testimonial={testimonial} key={testimonial.author} />
        ))}
      </Carousel>
    </Flex>
  );
};
