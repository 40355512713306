import { Link, useOutletContext } from "@remix-run/react";
import { User } from "@supabase/supabase-js";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { headerHeight } from "~/layouts/_app";

export function PilotBanner({
  message = "",
  absolutePosition = false,
}: {
  message?: string;
  absolutePosition?: boolean;
}) {
  const { user } = useOutletContext<{ user: User }>();
  const [isDismissed, setIsDismissed] = useState<boolean>(false);

  // Simple dismiss handler that only uses state (no localStorage)
  const handleDismiss = (): void => {
    setIsDismissed(true);
  };

  // Don't render if user is logged in or banner is dismissed during this session
  if (user || isDismissed) {
    return null;
  }

  return (
    <div
      className={`bg-gradient-to-r from-primary-600 to-secondary-600 text-white sticky z-40 ${
        absolutePosition ? "absolute" : ""
      }`}
      style={{ top: `${headerHeight}px` }}
    >
      <div className="max-w-7xl mx-auto py-3 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center text-center sm:flex-row sm:items-center sm:justify-between sm:text-left gap-2">
          <div className="flex-1">
            <p className="font-medium text-sm sm:text-base">{message}</p>
            <p className="font-medium text-sm sm:text-base">
              This site is the updated version of Podsie. To access the previous
              version, please go to{" "}
              <Link
                to="https://teacher.podsie.org"
                className="underline text-blue-100"
              >
                teacher.podsie.org
              </Link>{" "}
              &{" "}
              <Link
                to="https://student.podsie.org"
                className="underline text-blue-100"
              >
                student.podsie.org
              </Link>
              .
            </p>
          </div>
          <button
            type="button"
            className="ml-2 p-1 rounded-md hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-white"
            onClick={handleDismiss}
            aria-label="Dismiss banner"
          >
            <MdClose className="h-5 w-5 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
