import type { Attachment } from "@ai-sdk/ui-utils";
import { Box, Button, Flex, Group, Text } from "@mantine/core";
import { TbRefresh, TbRobot } from "react-icons/tb";
import { AssignmentEditorStartingForm } from "../AssignmentEditor/StartingForm";
import SignUpButton from "../Auth/SignUpButton";

const HeroH1 = () => {
  return (
    <Text
      component="h1"
      className="sm:text-5xl text-4xl font-bold text-text-primary leading-tight mb-6 p-0 text-center"
    >
      <Text
        component="span"
        variant="gradient"
        gradient={{ from: "secondary", to: "primary", deg: 90 }}
        className="sm:text-5xl text-4xl font-bold leading-tight m-0 p-0"
      >
        You have{" "}
        <Text
          component="span"
          className="sm:text-5xl text-4xl font-extrabold italic decoration-2"
        >
          a lot
        </Text>{" "}
        to cover —we want to help!
      </Text>
    </Text>
  );
};

const HeroSubtitle = () => {
  return (
    <Text className="text-text-secondary text-lg font-medium">
      Podsie provides pre-made practice content, an AI questions generator, and
      a personalized review system,
      <strong className="text-secondary-600"> completely free</strong>.
    </Text>
  );
};

const HeroCta = () => {
  return (
    <Flex direction="column" align="center">
      <Group className="mt-10 justify-center">
        <SignUpButton type="teacher" />
        <SignUpButton type="student" />
      </Group>
      <Text
        component="a"
        href="/partnership"
        className="mt-5 text-secondary-600 underline text-md font-semibold hover:text-secondary-700"
      >
        For School Leaders: Outcomes-Based Partnerships
      </Text>
    </Flex>
  );
};

const HeroInfoColumn = () => {
  return (
    <Flex direction="column" justify="center" className="h-full max-w-lg">
      <HeroH1 />
      <HeroSubtitle />
      <HeroCta />
    </Flex>
  );
};

// New component for the form section header
const FormSectionHeader = () => {
  return (
    <>
      <Text
        component="h2"
        className="text-center sm:text-4xl text-3xl font-bold text-text-secondary mb-6"
      >
        What do you want your students to master? 🧠
      </Text>
    </>
  );
};

// Function to handle starting over
const handleStartOver = () => {
  // Clear query string and refresh the page

  window.location.href = window.location.pathname;
};

export const HeroSection = ({
  handleStartTyping,
  hasExistingAssignment = false,
  resumeConversation,
}: {
  handleStartTyping: (input: string, attachments?: Attachment[]) => void;
  hasExistingAssignment?: boolean;
  resumeConversation?: () => void;
}) => {
  return (
    <Box className="grid md:grid-cols-2 grid-cols-1 min-h-[calc(100vh-200px)]">
      <Box className="bg-gradient-to-t from-secondary-50 to-white flex justify-center items-center px-8 md:px-16 py-10 md:py-0">
        <Box className="max-w-lg text-center">
          <HeroInfoColumn />
        </Box>
      </Box>
      <Box className="bg-gradient-to-b from-slate-100 to-primary-50 flex justify-center items-center px-8 md:px-16 py-10 md:py-0">
        <Box className="max-w-lg w-full py-6">
          <FormSectionHeader />

          {hasExistingAssignment && resumeConversation ? (
            <Flex justify="center" gap="md" direction="column">
              <Button
                onClick={resumeConversation}
                variant="outline"
                color="secondary"
                leftSection={<TbRobot />}
              >
                Resume Conversation
              </Button>
              <Button
                onClick={handleStartOver}
                variant="subtle"
                color="gray"
                leftSection={<TbRefresh size={16} />}
              >
                Start Over
              </Button>
            </Flex>
          ) : (
            <AssignmentEditorStartingForm onStartTyping={handleStartTyping} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
