import { Flex, Text } from "@mantine/core";
import PodsieFeatures from "./PodsieFeatures";

export const HowPodsieWorks = () => {
  return (
    <Flex
      direction="column"
      align="center"
      className="bg-gradient-to-t from-secondary-50 to-white p-6 pt-12 sm:p-16 sm:pt-24"
    >
      <Text
        component="h2"
        className="text-center sm:text-4xl text-3xl font-bold text-text-primary mb-12"
      >
        How Podsie Works
      </Text>
      <PodsieFeatures />
    </Flex>
  );
};
