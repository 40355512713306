import { Anchor, Flex, Text } from "@mantine/core";
import { Instagram } from "./Social/Instagram";
import { LinkedIn } from "./Social/LinkedIn";
import { X } from "./Social/X";

export const Footer = () => {
  return (
    <Flex
      justify="center"
      align="center"
      direction="column"
      className="bg-white p-6 sm:p-8"
    >
      <div className="flex justify-center w-full mb-4">
        <img
          src="/assets/podsie-full_logo-green.png"
          alt="Podsie Logo"
          className="h-auto w-auto max-w-40 max-h-16"
          style={{ objectFit: "contain" }}
        />
      </div>
      <Text
        component="p"
        className="text-text-secondary font-normal text-center mb-6"
      >
        Podsie is a part of the{" "}
        <a
          href="https://tools-competition.org/introducing-the-innovation-studio-creating-a-nonprofit-nexus-for-educations-innovators/"
          className="text-green-500 underline"
          target="_blank"
          rel="noreferrer"
        >
          Teaching Lab Innovation Studio
        </a>
      </Text>
      <Flex justify="center" align="center" mb="md">
        <X />
        <Instagram />
        <LinkedIn />
      </Flex>
      <Flex justify="center" align="center" wrap="wrap">
        <Flex direction="row" justify="center" align="center">
          <Anchor
            underline="hover"
            className="mb-6 mx-2"
            c="green"
            fw={600}
            fz="sm"
            href="mailto:hello@teachinglab.com"
            target="_blank"
          >
            Contact Us
          </Anchor>

          <Anchor
            underline="hover"
            className="mb-6 mx-2"
            c="green"
            fw={600}
            fz="sm"
            href="https://www.podsie.org/terms-and-conditions"
          >
            Terms of Use
          </Anchor>
        </Flex>
        <Flex direction="row" justify="center" align="center">
          <Anchor
            underline="hover"
            className="mb-6 mx-2"
            c="green"
            fw={600}
            fz="sm"
            href="https://www.podsie.org/privacy-policy"
          >
            Privacy Policy
          </Anchor>
          <Anchor
            underline="hover"
            className="mb-6 mx-2"
            c="green"
            fw={600}
            fz="sm"
            href="https://www.podsie.org/data-processing-agreement"
          >
            Data Processing
          </Anchor>
        </Flex>
      </Flex>
      <Text component="p" className="font-light text-center mb-4">
        © 2024 Podsie
      </Text>
    </Flex>
  );
};
