import type { Attachment } from "@ai-sdk/ui-utils";
import { Box, Button, Group } from "@mantine/core";
import { useState } from "react";
import { ModelProvider } from "~/ai-configs/models";
import { MessageInput } from "../AISdkAssistant/MessageInput";

interface TopicButtonProps {
  label: string;
  topic: string;
  onClick: (topic: string) => void;
}

function TopicButton({ label, topic, onClick }: TopicButtonProps) {
  return (
    <Button
      variant="outline"
      radius="xl"
      size="sm"
      color="secondary"
      className="bg-secondary-50 hover:bg-secondary-100 transition-all duration-300"
      onClick={() => onClick(topic)}
    >
      {label}
    </Button>
  );
}

interface StartingFormProps {
  onStartTyping: (input: string, attachments?: Attachment[]) => void;
}

export function AssignmentEditorStartingForm({
  onStartTyping,
}: StartingFormProps) {
  const [input, setInput] = useState("");
  const [modelProvider, setModelProvider] = useState<ModelProvider>("openai");

  const handleInputChange = (value: string) => {
    setInput(value);
  };

  // When the form is submitted, trigger the transition to the full editor
  const handleSubmit = (
    _event?: { preventDefault?: () => void } | undefined,
    chatRequestOptions?: Record<string, unknown> | undefined,
  ) => {
    if (input.trim()) {
      // Get the attachments from the chat request options if they exist
      const attachments = chatRequestOptions?.experimental_attachments as
        | Attachment[]
        | undefined;
      onStartTyping(input, attachments);
    }
  };

  const handleStop = () => {
    // Placeholder for MessageInput props
  };

  const handleTopicClick = (topic: string) => {
    setInput(topic);
  };

  return (
    <Box className="">
      <MessageInput
        editorClassName="min-h-[130px]"
        placeholder="Tell the AI generator what questions you'd like to create"
        disabled={false}
        input={input}
        modelProvider={modelProvider}
        onChange={handleInputChange}
        onModelChange={setModelProvider}
        onSubmit={handleSubmit}
        status="ready"
        onStop={handleStop}
      />
      <Group gap="xs" mt="md" justify="center">
        <TopicButton
          label="From Standard"
          topic="Create practice problems aligned with learning standard PS1.A: Each pure substance has characteristic physical and chemical properties."
          onClick={handleTopicClick}
        />
        <TopicButton
          label="From Image"
          topic="Create practice problems based on the image I'll attach"
          onClick={handleTopicClick}
        />
        <TopicButton
          label="From Document"
          topic="Generate practice problems from the PDF document I'll share"
          onClick={handleTopicClick}
        />
        <TopicButton
          label="Specific Topic"
          topic="Create practice problems on the following science topic: meiosis vs mitosis"
          onClick={handleTopicClick}
        />
        <TopicButton
          label="Custom Question"
          topic="Create a question that asks students to identify the products of photosynthesis"
          onClick={handleTopicClick}
        />
      </Group>
    </Box>
  );
}
