import { Button, Progress, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { TbCheck, TbChevronRight, TbLanguage, TbX } from "react-icons/tb";

interface Question {
  id: string;
  text: string;
  correctAnswer: string;
}

// 8th grade NGSS questions
const demoQuestions: Question[] = [
  {
    id: "q1",
    text: "What is the primary cause of seasons on Earth?",
    correctAnswer: "earth's axial tilt",
  },
  {
    id: "q2",
    text: "What happens to the mass of substances during a chemical reaction?",
    correctAnswer: "remains the same",
  },
  {
    id: "q3",
    text: "What are the main products of photosynthesis?",
    correctAnswer: "glucose and oxygen",
  },
];

const CorrectnessIcon = ({ isCorrect }: { isCorrect?: boolean }) => {
  if (isCorrect === undefined) return null;

  return isCorrect ? (
    <span className="text-green-500">
      <TbCheck size={20} />
    </span>
  ) : (
    <span className="text-red-500">
      <TbX size={20} />
    </span>
  );
};

const PersonalizedPractice = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState<boolean | undefined>(undefined);
  const [animationStep, setAnimationStep] = useState(0);
  const [typingIndex, setTypingIndex] = useState(0);

  const currentQuestion = demoQuestions[currentQuestionIndex];
  const progress = {
    completed: currentQuestionIndex,
    total: demoQuestions.length,
  };

  // Typing animation for short answer
  useEffect(() => {
    if (
      animationStep === 1 &&
      typingIndex <
        (currentQuestionIndex === 2
          ? "glucose and carbon dioxide"
          : currentQuestion.correctAnswer
        ).length
    ) {
      const timer = setTimeout(() => {
        setUserAnswer(
          (currentQuestionIndex === 2
            ? "glucose and carbon dioxide"
            : currentQuestion.correctAnswer
          ).substring(0, typingIndex + 1),
        );
        setTypingIndex(typingIndex + 1);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [animationStep, currentQuestion, typingIndex, currentQuestionIndex]);

  // Main animation sequence
  useEffect(() => {
    const animationSequence = [
      // Reset state for new question
      () => {
        setUserAnswer("");
        setIsSubmitted(false);
        setIsCorrect(undefined);
        setTypingIndex(0);
      },
      // Show typing
      () => {
        // Typing effect is handled by the separate useEffect
      },
      // Submit answer
      () => {
        setIsSubmitted(true);
        setIsCorrect(currentQuestionIndex !== 2); // Only incorrect for photosynthesis question
      },
      // Pause to show result
      () => {
        // Just wait in this state
      },
      // Move to next question
      () => {
        setCurrentQuestionIndex((prev) => (prev + 1) % demoQuestions.length);
      },
    ];

    const timings = [0, 1500, 1500, 2000, 1000];

    if (
      !(
        animationStep === 1 &&
        typingIndex <
          (currentQuestionIndex === 2
            ? "Glucose and carbon dioxide"
            : currentQuestion.correctAnswer
          ).length
      )
    ) {
      const timer = setTimeout(() => {
        animationSequence[animationStep]();
        setAnimationStep((prev) => (prev + 1) % animationSequence.length);
      }, timings[animationStep]);

      return () => clearTimeout(timer);
    }
  }, [animationStep, currentQuestion, typingIndex, currentQuestionIndex]);

  return (
    <div className="bg-custom-gradient rounded-lg shadow-md p-6 w-full mx-auto min-h-[520px]">
      <div className="flex flex-col gap-4 h-[450px] bg-white p-4 rounded-lg">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-1">
            <Text size="sm" className="text-gray-600">
              {progress.completed} of {progress.total} questions completed
            </Text>
            <Progress
              value={(progress.completed / progress.total) * 100}
              size="xs"
              color="primary"
            />
          </div>
          <Button
            variant="outline"
            color="primary"
            leftSection={<TbLanguage size={18} />}
            size="sm"
            className="pointer-events-none"
          >
            Translate to...
          </Button>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <Text className="font-medium flex items-center">Question:</Text>
            <CorrectnessIcon isCorrect={isSubmitted ? isCorrect : undefined} />
          </div>
          <Text>{currentQuestion.text}</Text>
        </div>

        <div className="flex flex-col gap-2">
          {!isSubmitted && (
            <Text className="font-medium">Enter an answer:</Text>
          )}

          <div className="flex flex-col gap-2">
            <div className="border border-gray-300 rounded-md p-2 w-full min-h-[40px]">
              {userAnswer}
              {animationStep === 1 && !isSubmitted && (
                <span className="inline-block w-[2px] h-[18px] bg-gray-500 ml-[1px] animate-pulse"></span>
              )}
            </div>
          </div>

          {isSubmitted && (
            <div className="bg-gray-50 p-3 rounded-md border border-gray-200 mt-2">
              <Text className="font-medium">
                {isCorrect ? "Correct!" : "Incorrect"}
              </Text>
              <Text size="sm" className="text-gray-700">
                {isCorrect
                  ? `"${currentQuestion.correctAnswer}" is correct.`
                  : `The correct answer is "${currentQuestion.correctAnswer}".`}
              </Text>
            </div>
          )}
        </div>

        <div className="flex-grow"></div>

        <div className="flex justify-end mt-4">
          {!isSubmitted ? (
            <Button
              rightSection={<TbChevronRight size={20} />}
              className="bg-primary-500 hover:bg-primary-600 text-white pointer-events-none"
            >
              Submit Answer
            </Button>
          ) : (
            <Button
              rightSection={<TbChevronRight size={20} />}
              className="bg-primary-500 hover:bg-primary-600 text-white pointer-events-none"
            >
              Next Question
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalizedPractice;
