import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { colors } from "../../styles/colors";

interface StandardColors {
  // Physical Science (PS) - 4 standards
  "PS1.A": string;
  "PS2.B": string;
  "PS3.A": string;
  "PS4.A": string;
  // Life Science (LS) - 4 standards
  "LS1.A": string;
  "LS2.A": string;
  "LS3.B": string;
  "LS4.C": string;
  // Earth and Space Science (ESS) - 4 standards
  "ESS1.B": string;
  "ESS2.A": string;
  "ESS2.C": string;
  "ESS3.C": string;
}

type Standard = keyof StandardColors;

interface Reviews {
  [key: number]: Standard[];
}

interface Student {
  emoji: string;
  name: string;
  reviews: Reviews;
}

// 1) Define your color mapping for standards
const standardColors: StandardColors = {
  // Physical Science (PS) - blue/purple palette
  "PS1.A": "bg-blue-100",
  "PS2.B": "bg-indigo-100",
  "PS3.A": "bg-purple-100",
  "PS4.A": "bg-violet-100",

  // Life Science (LS) - green palette
  "LS1.A": "bg-green-100",
  "LS2.A": "bg-emerald-100",
  "LS3.B": "bg-teal-100",
  "LS4.C": "bg-lime-100",

  // Earth and Space Science (ESS) - warm palette
  "ESS1.B": "bg-orange-100",
  "ESS2.A": "bg-amber-100",
  "ESS2.C": "bg-yellow-100",
  "ESS3.C": "bg-red-100",
};

// 2) Helper to create a day-to-standards mapping
const generateReviews = (pattern: Standard[][], totalDays: number) => {
  const reviews: Reviews = {};
  for (let day = 1; day <= totalDays; day++) {
    const patternIndex = (day - 1) % pattern.length;
    reviews[day] = pattern[patternIndex];
  }
  return reviews;
};

const students: Student[] = [
  {
    emoji: "👧",
    name: "Emma",
    reviews: generateReviews(
      [
        ["PS1.A", "LS1.A", "ESS1.B", "PS3.A"],
        ["LS2.A", "ESS2.A", "PS2.B"],
        ["LS3.B", "ESS2.C", "PS1.A", "LS4.C"],
        ["ESS3.C", "PS4.A", "LS1.A"],
      ],
      30,
    ),
  },
  {
    emoji: "👦",
    name: "James",
    reviews: generateReviews(
      [
        ["PS2.B", "ESS2.A"],
        ["LS3.B", "PS1.A", "ESS3.C", "LS2.A"],
        ["PS4.A", "LS4.C", "ESS1.B"],
        ["PS3.A", "ESS2.C", "LS1.A", "PS2.B"],
      ],
      30,
    ),
  },
  {
    emoji: "👩‍🦱",
    name: "Sofia",
    reviews: generateReviews(
      [
        ["LS4.C", "LS1.A", "PS1.A", "ESS2.C"],
        ["ESS1.B", "PS3.A"],
        ["LS2.A", "ESS3.C", "LS3.B", "PS4.A"],
        ["PS2.B", "ESS2.A", "LS4.C"],
      ],
      30,
    ),
  },
];

// Define the glow effect styles using the colors from colors.ts
const glowStyles = {
  boxShadow: `0 0 8px 1px ${colors.primary[50]}, 
               0 0 12px 2px ${colors.secondary[50]}`,
};

const HeroArtifact = () => {
  const [currentDay, setCurrentDay] = useState(1);
  const totalDays = 30;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDay((prev) => (prev === totalDays ? 1 : prev + 1));
    }, 1800); // Change day every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="w-full max-w-full sm:max-w-2xl mx-auto p-6 space-y-5 bg-white rounded-md relative min-h-[520px]"
      style={glowStyles}
    >
      {/* Show the current day */}
      <div className="text-center text-gray-700 font-semibold">
        Day {currentDay} of {totalDays}
      </div>

      {/* Shared label for all students */}
      <div className="text-md font-medium text-gray-700 mb-2">
        Standards covered in today&apos;s personalized review:
      </div>

      {/* Student Cards */}
      <div className="space-y-6">
        {students.map((student, idx) => {
          const standardsToday = student.reviews[currentDay];
          return (
            <motion.div
              key={idx}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
              className="bg-gray-50 rounded-lg p-4 shadow-sm"
            >
              <div className="flex items-center mb-2">
                <span className="text-3xl mr-3">{student.emoji}</span>
                <h2 className="font-semibold text-lg text-primary">
                  {student.name}
                </h2>
              </div>

              <div className="mt-4">
                <div className="flex flex-wrap gap-2">
                  {standardsToday.map((std, i) => (
                    <div
                      key={i}
                      className={`px-2 py-1 rounded text-secondary-700 text-sm font-medium ${standardColors[std]}`}
                    >
                      {std}
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default HeroArtifact;
