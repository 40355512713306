import { Flex, Grid, Text } from "@mantine/core";
import AnalyticsDashboard from "./animated-components/AnalyticsDashboard";
import PersonalizedPractice from "./animated-components/PersonalizedPractice";
import HeroArtifact from "./HeroArtifact";

type FeatureData = {
  title: string;
  description: string;
  image?: string;
  alt?: string;
  component?: React.ReactNode;
};

const features: FeatureData[] = [
  {
    title: "🧑‍💻 Practice That Builds Strong Foundations",
    description:
      "Students engage with practice questions that you assign. You can quickly put together practice sets from our pre-made content library or the AI questions generator.",
    component: <PersonalizedPractice />,
  },
  {
    title: "📊 Targeted Review Based on Student Needs",
    description:
      "Over time, each student receives different practice sets based on their performance. Students who struggle with specific concepts get more practice where they need it most.",
    component: <HeroArtifact />,
  },
  {
    title: "🔍 Spot Knowledge Gaps at a Glance",
    description:
      "See exactly which standards each student is mastering or struggling with. Quickly identify who needs additional support and on which specific concepts.",
    component: <AnalyticsDashboard />,
  },
];

const FeatureDescription = ({ feature }: { feature: FeatureData }) => (
  <Flex
    direction="column"
    className="flex flex-col justify-center h-full text-center md:text-left"
  >
    <Text component="h3" className="text-2xl font-bold text-text-primary mb-4">
      {feature.title}
    </Text>
    <Text className="text-text-secondary font-normal">
      {feature.description}
    </Text>
  </Flex>
);

export default function ProductFeatures() {
  return (
    <div className="max-w-screen-lg mx-auto px-4 w-full">
      {features.map((feature) => (
        <div key={feature.title} className="mb-20 md:mb-16 last:mb-0">
          <Grid gutter={{ base: 20, md: 50, lg: 80 }} justify="center">
            <Grid.Col
              span={{ base: 12, md: 6 }}
              className="flex flex-col gap-6 mb-6 md:mb-0"
            >
              <FeatureDescription feature={feature} />
            </Grid.Col>
            <Grid.Col
              span={{ base: 12, md: 6 }}
              className="flex flex-col gap-6 mb-8 md:mb-0"
            >
              <div className="w-full flex justify-center">
                <div className="w-full max-w-[380px] sm:max-w-full">
                  {feature.component}
                </div>
              </div>
            </Grid.Col>
          </Grid>
        </div>
      ))}
    </div>
  );
}
