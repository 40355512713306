import { Flex, Text } from "@mantine/core";
import SignUpButton from "../Auth/SignUpButton";

export const Cta = () => {
  return (
    <Flex justify="center" align="center" className="bg-secondary-50 p-24">
      <Flex
        justify="center"
        align="center"
        direction="column"
        className="max-w-screen-md"
      >
        <Text
          component="h2"
          className="text-text-primary sm:text-3xl text-2xl font-bold text-center mb-6"
        >
          Ready to get started?
        </Text>
        <Text
          component="p"
          className="text-text-secondary text-xl font-normal text-center mb-6"
        >
          Start improving student learning today with Podsie!
        </Text>
        <Flex justify="center" align="center" direction="row" gap="md">
          <SignUpButton type="teacher" />
          <SignUpButton type="student" />
        </Flex>
      </Flex>
    </Flex>
  );
};
