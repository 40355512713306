import { Table, Text, Tooltip } from "@mantine/core";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { colors } from "../../../styles/colors";

// Define the standards (using the same ones from HeroArtifact for consistency, but removing PS3.A)
const standards = ["PS1.A", "LS1.A", "ESS1.B"];

// Define student data with performance percentages for each standard
interface StudentData {
  id: string;
  name: string;
  emoji: string;
  performance: {
    [key: string]: number; // standard code -> percentage
  };
  needsAttention?: boolean;
}

const students: StudentData[] = [
  {
    id: "s1",
    name: "Emma",
    emoji: "👧",
    performance: {
      "PS1.A": 92,
      "LS1.A": 85,
      "ESS1.B": 78,
    },
  },
  {
    id: "s2",
    name: "James",
    emoji: "👦",
    performance: {
      "PS1.A": 65,
      "LS1.A": 72,
      "ESS1.B": 58,
    },
    needsAttention: true,
  },
  {
    id: "s3",
    name: "Sofia",
    emoji: "👩‍🦱",
    performance: {
      "PS1.A": 88,
      "LS1.A": 91,
      "ESS1.B": 83,
    },
  },
  {
    id: "s4",
    name: "Alex",
    emoji: "🧑",
    performance: {
      "PS1.A": 79,
      "LS1.A": 84,
      "ESS1.B": 76,
    },
  },
];

// Helper function to get color based on performance
const getPerformanceColor = (percentage: number): string => {
  if (percentage >= 85) return "bg-green-100 text-green-800";
  if (percentage >= 70) return "bg-blue-100 text-blue-800";
  if (percentage >= 60) return "bg-yellow-100 text-yellow-800";
  return "bg-red-100 text-red-800";
};

// Define the glow effect styles using the colors from colors.ts (same as HeroArtifact)
const glowStyles = {
  boxShadow: `0 0 8px 1px ${colors.primary[50]}, 
               0 0 12px 2px ${colors.secondary[50]}`,
};

const AnalyticsDashboard = () => {
  // Define the sequence outside useEffect so we can access it for initial state
  const sequence = [
    { studentId: "s2", standard: "ESS1.B" }, // James's low Earth Science score
    { studentId: "s2", standard: "PS1.A" }, // James's Physical Science score
  ];

  // State to track which cell is being "focused" by the teacher
  // Initialize with null to start with no focus
  const [focusedCell, setFocusedCell] = useState<{
    studentId: string;
    standard: string;
  } | null>(null);

  // State to track if James' row is highlighted
  const [highlightJames, setHighlightJames] = useState(false);

  // Animation sequence to simulate teacher interaction
  useEffect(() => {
    // Animation sequence:
    // 1. Start with no highlights
    // 2. Highlight James' row
    // 3. Focus on James' ESS1.B cell
    // 4. Focus on James' PS1.A cell
    // 5. Reset and repeat

    // First, highlight James' row after a delay
    const highlightTimeout = setTimeout(() => {
      setHighlightJames(true);
    }, 1500);

    // Then start the cell focus sequence after another delay
    const focusTimeout = setTimeout(() => {
      setFocusedCell(sequence[0]);

      let currentIndex = 1; // Start from the second item
      const interval = setInterval(() => {
        if (currentIndex === 0) {
          // Reset the highlight and focus when we loop back to the beginning
          setHighlightJames(false);
          setFocusedCell(null);

          // Then highlight James' row again after a delay
          setTimeout(() => {
            setHighlightJames(true);
          }, 1500);

          // And start focusing on cells again after another delay
          setTimeout(() => {
            setFocusedCell(sequence[0]);
          }, 3000);
        } else {
          setFocusedCell(sequence[currentIndex]);
        }

        currentIndex = (currentIndex + 1) % sequence.length;
      }, 2000);

      return () => clearInterval(interval);
    }, 3000);

    return () => {
      clearTimeout(highlightTimeout);
      clearTimeout(focusTimeout);
    };
  }, []);

  // Cursor component that follows the focused cell
  const TeacherCursor = () => {
    if (!focusedCell) return null;

    // Instead of showing a pointer emoji, we'll just use the cell highlighting
    // The motion animation for the cells is handled in the table rendering
    return null;
  };

  return (
    <div
      className="w-full bg-white max-w-full sm:max-w-2xl mx-auto p-6 space-y-5 rounded-md relative flex flex-col justify-center"
      style={glowStyles}
    >
      <div className="text-center text-gray-700 font-semibold mb-4">
        Student Performance Dashboard
      </div>

      <div className="overflow-x-auto bg-slate-50">
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th className="w-48 min-w-[12rem]">Student</Table.Th>
              {standards.map((standard) => (
                <Table.Th key={standard} className="text-center">
                  {standard}
                </Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {students.map((student) => (
              <Table.Tr
                key={student.id}
                className={
                  student.id === "s2" && highlightJames ? "bg-secondary-50" : ""
                }
              >
                <Table.Td>
                  <div className="flex items-center gap-2">
                    <span className="text-xl">{student.emoji}</span>
                    <div>
                      <Text
                        fw={student.id === "s2" && highlightJames ? 600 : 400}
                      >
                        {student.name}
                      </Text>
                    </div>
                    <span
                      className={`text-xs px-2 py-0.5 rounded-full transition-opacity duration-300 ${
                        student.id === "s2"
                          ? highlightJames
                            ? "bg-yellow-100 text-yellow-800 opacity-100"
                            : "opacity-0"
                          : "opacity-0 invisible"
                      }`}
                    >
                      Needs help
                    </span>
                  </div>
                </Table.Td>
                {standards.map((standard) => {
                  const percentage = student.performance[standard];
                  const colorClass = getPerformanceColor(percentage);
                  const isFocused =
                    focusedCell?.studentId === student.id &&
                    focusedCell?.standard === standard;

                  return (
                    <Table.Td
                      key={`${student.id}-${standard}`}
                      id={`cell-${student.id}-${standard}`}
                      className="text-center relative"
                    >
                      <Tooltip
                        label={`${student.name}'s mastery of ${standard}`}
                      >
                        <motion.div
                          className={`inline-block px-2 py-1 rounded-md ${colorClass} ${isFocused ? "ring-2 ring-primary" : ""}`}
                          animate={isFocused ? { scale: 1.1 } : { scale: 1 }}
                          transition={{ duration: 0.3 }}
                        >
                          {percentage}%
                        </motion.div>
                      </Tooltip>
                    </Table.Td>
                  );
                })}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </div>

      {/* Teacher cursor animation */}
      <TeacherCursor />
    </div>
  );
};

export default AnalyticsDashboard;
